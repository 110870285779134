<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>

              <div class="d-flex align-items-center">
                <!-- <el-button class="btn new-btn mr-3" @click="$router.push({path: '/ordem'})">Cadastrar Novo</el-button> -->
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>
            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.holder.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>
                <el-table-column v-if="total > 0" align="center" label="Ações">
                  <div slot-scope="props">
                    <base-button v-if="!props.row.billet" class="edit btn-link" type="gray" size="sm" icon title="Exibir documento">
                      <a :href="`/${props.payment_voucher}`" target="_blank">
                        <i class="fas fa-eye"></i>
                      </a>
                    </base-button>
                    <base-button v-if="!props.row.billet" @click.native="handleBillet(props.row, props.$index)" class="edit btn-link" type="gray" size="sm" icon title="Anexar boleto">
                      <a target="_blank">
                        <i class="fa fa-barcode"></i>
                      </a>
                    </base-button>
                    <a v-if="props.row.billet" target="_blank" :href="props.row.billet" class="text-info billet-link">
                      <i class="fa fa-barcode"></i>
                    </a>
                    <base-button v-if="props.row.status === 2" @click.native="handleConfirmPaid(props.$index, props.row)" class="edit btn-link" type="success" size="sm" icon title="Aprovar">
                      <i class="fa fa-check"></i>
                    </base-button>
                    <base-button v-if="props.row.status === 2" @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon title="Cancelar depósito">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                    <base-button title="Alterar status para aguardando pagamento" v-if="props.row.status !== 2" @click.native="handleUndo(props.$index, props.row)" class="remove btn-link" type="info" size="sm" icon>
                      <i class="fa fa-undo-alt"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="modal" headerClasses="justify-content-center" centered>
      <h4 slot="header" class="title title-up">Cadastrar boleto</h4>

      <div class="col-12 p-0 mb-3">
        <div class="form-group">
          <label for="billet">Boleto: {{ dataBillet.billet_name }}</label>
          <input type="file" class="form-control" @change="changeBillet" />
          <label for="billet" class="form-control">
            {{dataBillet.billet.name ? dataBillet.billet.name : 'Selecionar Boleto'}}
          </label>
        </div>
      </div>

      <template slot="footer">
        <base-button :disabled="loadingBillet" type="danger" @click.native="cancelBillet()">Cancelar</base-button>
        <base-button :disabled="loadingBillet" type="primary" @click.native="billet()">{{ loadingBillet ? 'Salvando...' : 'Salvar' }}</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination, Modal } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      loadingBillet: false,
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      dataBillet: {
        billet: {}
      },
      modal: false,
      error: false
    }
  },
  methods: {
    handleBillet (row, index) {
      this.dataBillet.billet_id = row.id
      this.dataBillet.billet_name = row.holder
      this.dataBillet.index = index
      this.modal = true
    },
    async billet () {
      if (this.dataBillet.billet.name) {
        this.loadingBillet = true
        this.error = false

        const url = `financial/bank-log/billet/${this.dataBillet.billet_id}`

        const data = new FormData()
        data.append('billet', this.dataBillet.billet)

        const res = await this.$http.post(url, data)

        if (res.status === 200) {
          this.modal = false
          swal({
            title: 'Cadastrado!',
            text: 'Boleto salvo com sucesso',
            showConfirmButton: false,
            timer: 1000
          })
          this.getData()
        } else {
          swal({
            title: 'Oops!',
            text: 'Ocorreu um erro ao salvar boleto',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
          this.error = true
        }
        this.loadingBillet = false
      } else {
        swal({
          title: 'Oops!',
          text: 'Favor, selecione o boleto',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
        this.error = true
      }
    },
    changeBillet (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const file = files[0]
      this.dataBillet.billet = file
    },
    cancelBillet () {
      this.dataBillet = { billet: {} }
      this.modal = false
    },
    handleUndo (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a alterar o status do depósito "${row.id}". para "Aguardando Pagamento"`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero confirmar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal({
            title: 'Alterando',
            text: `Aguarde, estamos alterando o depósito "${row.id}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.undo(row).then(status => {
            if (status) {
              swal({
                title: 'Alterado!',
                text: `Você alterou o depósito "${row.id}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal({
                title: 'Oops!',
                text: `Ocorreu um erro ao alterar o depósito "${row.id}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async undo (row) {
      const data = JSON.parse(JSON.stringify(row))
      data.status = 2
      let status
      try {
        status = (await this.$http.put(`financial/bank-log/${row.id}`, data)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToConfirm = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToConfirm >= 0) {
          this.tableData[indexToConfirm].status_label = 'Aguardando Confirmação'
        }
        return true
      }
      return false
    },
    handleConfirmPaid (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a confirmar o depósito "${row.id}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero confirmar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Confirmando',
            text: `Aguarde, estamos confirmando o depósito "${row.id}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.confirmPaid(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Confirmado!',
                text: `Você confirmou o depósito "${row.id}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal({
                title: 'Oops!',
                text: `Ocorreu um erro ao confirmar o depósito "${row.id}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async confirmPaid (row) {
      const data = JSON.parse(JSON.stringify(row))
      data.status = 1
      let status
      try {
        status = (await this.$http.put(`financial/bank-log/${row.id}`, data)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToConfirm = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToConfirm >= 0) {
          this.tableData[indexToConfirm].status_label = 'Confirmado'
        }
        return true
      }
      return false
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a cancelar o depósito "${row.id}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero cancelar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Cancelando',
            text: `Aguarde, estamos cancelando o depósito "${row.id}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Cancelado!',
                text: `Você cancelou o depósito "${row.id}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Oops!',
                text: `Ocorreu um erro ao cancelar o depósito "${row.id}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      const data = JSON.parse(JSON.stringify(row))
      data.status = 0
      let status
      try {
        status = (await this.$http.put(`financial/bank-log/${row.id}`, data)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToConfirm = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToConfirm >= 0) {
          this.tableData[indexToConfirm].status_label = 'Confirmado'
        }
        return true
      }
      return false
    },
    async getData () {
      this.tableData = (await this.$http.get('financial/bank-log?type=ENTRADA&receiver=RECARGA')).data.data

      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 20
        },
        {
          prop: 'holder',
          label: 'Titular da Conta',
          minWidth: 60
        },
        {
          prop: 'cpf_cnpj',
          label: 'CPF/CNPJ',
          minWidth: 40
        },
        {
          prop: 'value',
          label: 'Valor',
          minWidth: 30
        },
        {
          prop: 'created',
          label: 'Data do Pedido',
          minWidth: 30
        },
        {
          prop: 'status_label',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['title', 'type']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
.billet-link {
  width: 20px;
  height: 15px;
  display: flex;
  float: left;
  margin-top: 9px;
}
</style>
